import { getState, getAuthState, getFrameworkVars } from "@sparky/framework";
import { SPARKY_NAV3_ROUTES_EXTPOINT } from '@sparky/framework/extpoints';
import { REPORTING_ASSOCIATED_APPS } from "../ReportingAssociatedApps";
import type { ExtensionsConfig, SparkyNavRouteConfig, StoreState } from "@sparky/framework/extpoints";

function getReportsById () {
  const { reporting = {} } = getState() || {};
  return reporting?.reportsById;
}

function hideReporting () {
  // disable dashboards/reporting for enterprise dlp or casb only tenants
  // load reporting pages only if associated apps are present
  const instances = getAuthState()?.instances;
  const found = REPORTING_ASSOCIATED_APPS.some(app => instances?.isRunningStatus(app));
  if (!found) {
    return true;
  }
  return false;
}
const isUserPreferencesSupported = Boolean(getFrameworkVars()?.user_preferences_ui);
let theme = "";
if (!isUserPreferencesSupported) {
  theme = "sparky-dark-only";
}
if (getFrameworkVars()?.pdf_mode) {
  theme = "sparky-light-only";
}

const contentClassName = "reports-root panwds-tw3" + theme;

export default {
  point: SPARKY_NAV3_ROUTES_EXTPOINT,
  configs: [
    {
      id: 'netsec.vnr.routes.security.insights.actvity.insights',
      config: {
        path: '/insights/activity_insights',
        // replacePaths: '/insights/activity_insights',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: (state: StoreState) => {
          return {
            main: state.main,
            id: "unified_network_activity_report",
            reporting: state.reporting,
            leftMainNavigation: state.leftMainNavigation,
            theme:
                            state.theme === "light" || state.theme === "dark"
                              ? state.theme
                              : "light"
          };
        },
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../UnifiedNetworkActivity"), // any component must be dynamically import like this
        isAllowed: () => {
          if (hideReporting()) {
            return false;
          }
          const reportById = getReportsById();
          return reportById ? reportById.unified_network_activity_report.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.threats.intelligence',
      config: {
        path: '/insights/security/threats-intelligence',
        replacePaths: '/monitor/search',

        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Search"), // any component must be dynamically import like this
        isAllowed: () => {
          if (hideReporting()) {
            return false;
          }
          const reportById = getReportsById();

          return reportById ? reportById.ioc_report.allowed : false;
        }
      }
    },
    // deprecated except for few regions
    {
      id: 'netsec.vnr.routes.security.insights.app.usage',
      config: {
        path: '/insights/security/app_usage',
        replacePaths: '/dashboards/app_usage',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.app_usage_report?.allowed : false;
        }
      }
    },
    // deprecated except for few regions
    {
      id: 'netsec.vnr.routes.security.insights.threats.intelligence.pdf',
      config: {
        path: '/monitor/ioc_search_pdf',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName: "reports-root panwds-tw3 sparky-light-only", // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () =>
          await import("../views/PDFReport/IOCFileHashPDFReport"), // any component must be dynamically import like this
        isAllowed: () => {
          if (hideReporting()) {
            return false;
          }
          const reportById = getReportsById();
          return reportById ? reportById.ioc_report.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.threat.insights',
      config: {
        path: '/insights/security/threat_insights',
        replacePaths: '/dashboards/threat_insights',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          if (hideReporting()) {
            return false;
          }
          const reportById = getReportsById();

          return reportById ? reportById?.threat_insights_report.allowed : false;
        }
      }
    },
    // deprecated except for few regions
    {
      id: 'netsec.vnr.routes.security.insights.threat.activity',
      config: {
        path: '/insights/security/threat_activity',
        replacePaths: '/dashboards/threat_activity',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          if (hideReporting()) {
            return false;
          }
          const reportById = getReportsById();

          return reportById ? reportById?.threat_activity_report.allowed : false;
        }
      }
    },
    // deprecated except for few regions
    {
      id: 'netsec.vnr.routes.security.insights.network.activity',
      config: {
        path: '/insights/security/network_activity/*',
        replacePaths: '/dashboards/network_activity/*',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.network_activity_report?.allowed : false;
        }
      }
    },
    // deprecated except for few regions
    {
      id: 'netsec.vnr.routes.security.insights.uar',
      config: {
        path: '/insights/security/uar',
        replacePaths: '/dashboards/uar',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.user_activity_report?.allowed : false;
        }
      }
    },
    // reports only view for v3
    // {
    //   id: 'netsec.vnr.routes.security.insights.executive',
    //   config: {
    //     path: '/insights/security/executive',
    //     replacePaths: '/dashboards/executive',
    //     stateLoaders: ['auth', 'reporting'],
    //     // exact: false,
    //     mapStateToProps: () => ({ }),
    //     contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
    //     component: async () => await import("../Dashboard"), // any component must be dynamically import like this
    //     isAllowed: () => {
    //       const reportById = getReportsById();
    //       return reportById ? reportById?.executive_report?.allowed : false;
    //     }
    //   }
    // },
    {
      id: 'netsec.vnr.routes.operational.insights.usage',
      config: {
        path: '/insights/operational/prisma-sase/usage',
        replacePaths: '/dashboards/usage',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.prisma_access_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.dns',
      config: {
        path: '/insights/security/dns/*',
        replacePaths: '/dashboards/dns/*',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.dns_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.wildfire',
      config: {
        path: '/insights/security/wildfire',
        replacePaths: '/dashboards/wildfire',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.wildfire_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.advanced.threat.prevention',
      config: {
        path: '/insights/security/advanced_threat_prevention',
        replacePaths: '/dashboards/advanced_threat_prevention',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.advanced_threat_prevention_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.bpa',
      config: {
        path: '/insights/security/posture/bpa/*',
        replacePaths: '/dashboards/bpa/*',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.best_practices_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.logviewer',
      config: {
        path: '/logviewer',
        replacePaths: '/incidents-alerts/logviewer',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: (state: StoreState) => {
          return {
            main: state.main,
            flattenNavigationList:
                            state.leftMainNavigation?.flattenNavigationList ?? [],
            leftMainNavigation: state.leftMainNavigation,
            reporting: state.reporting,
            theme:
                            state.theme === "light" || state.theme === "dark"
                              ? state.theme
                              : "light"
          };
        },
        contentClassName: 'panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import('../LogViewer'), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById.log_viewer.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.dashboard.editor',
      config: {
        path: '/insights/dashboard_editor/:dashboardId?',
        replacePaths: '/dashboard_editor/:dashboardId?',
        stateLoaders: ['auth', 'reporting'],
        mapStateToProps: () => ({ }),
        contentClassName: 'panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import('../Dashboard'), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById.dashboard_editor.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.custom.dashboard',
      config: {
        path: '/insights/dashboards/:dashboardId?',
        replacePaths: '/dashboards/:dashboardId?',
        stateLoaders: ['auth', 'reporting'],
        mapStateToProps: () => ({ }),
        contentClassName: 'panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import('../Dashboard'), // any component must be dynamically import like this
        isAllowed: () => {
          return true;
        }
      }
    }
  ]
} satisfies ExtensionsConfig<SparkyNavRouteConfig>;
