import { getState, getFeatureFlag } from "@sparky/framework";
import { isNil } from "lodash";

export const getTenantInfo = (authData: any, paEnabled?: boolean) => {
  const hasCDL = authData?.instances?.isRunningStatus("logging_service");
  const isV3Nav = getFeatureFlag('sparky.framework.use_scm_nav_v3');
  return {
    isADEMTier1Role: getState()?.auth?.access?.hasRole("adem_tier_1_support"),
    isSDWANOnlyTenant:
      authData?.instances?.length === 1 && authData?.instances[0]?.app_id === "cgx",
    hasSDWAN: !isNil(authData?.instances.find((i: Record<string, string>) => i.app_id === "cgx")),
    paEnabled,
    hasCDL,
    isV3Nav
  };
};
