import * as sparkyFramework from "@sparky/framework";
import { SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT } from '@sparky/framework/extpoints';
import type { ExtensionsConfig, StateLoaderConfig } from "@sparky/framework/extpoints";
import { getParams } from "../init";

export default {
  point: SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
  configs: [{
    id: 'sparky.nav.state.loaders.reporting',
    config: {
      name: 'reporting', // this is the key, means it can access by state.main
      requireFetch: true,
      loader: async () => {
        let reporting = {};
        const apiPrefetchRes = await import("@reporting-n-viz/vnr-entrypoint/dist/api-prefetch").catch((e) => {
          console.log('Error fetching api-prefetch', e);
        });
        const { prefetch } = apiPrefetchRes;
        if (prefetch) {
          const params = getParams(sparkyFramework, sparkyFramework?.getMicroAppVars?.("reporting_oneapp"), true, (innerParams: any) => {
            reporting = innerParams.reporting;
            if (innerParams.reporting.customDashboards) {
              sparkyFramework?.actions?.setValues({ reporting });
              sparkyFramework?.actions?.forceRerenderLeftNav();
            }
          }) as Parameters<(apiEndpoint: string, params: any, callback: (response: any) => void) => Promise<void>>;
          await prefetch(...params);
        }
        return reporting;
      }
    }
  }]
} satisfies ExtensionsConfig<StateLoaderConfig>;
