/* eslint-disable @typescript-eslint/no-var-requires */

import { getState, getAuthState } from "@sparky/framework";
import { SPARKY_NAV3_LEFT_NAV_EXTPOINT } from '@sparky/framework/extpoints';
import { REPORTING_ASSOCIATED_APPS } from "../ReportingAssociatedApps";
import type { ExtensionsConfig, SparkyLeftNavConfig } from "@sparky/framework/extpoints";

function getReportsById () {
  const { reporting = {} } = getState() || {};
  return reporting?.reportsById;
}
function hideReporting () {
  // disable dashboards/reporting for enterprise dlp or casb only tenants
  // load reporting pages only if associated apps are present
  const instances = getAuthState()?.instances;
  const found = REPORTING_ASSOCIATED_APPS.some(app => instances?.isRunningStatus(app));
  if (!found) {
    return true;
  }
  return false;
}

// below is for nav v3 using extensions
export default {
  point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  configs: [
    {
      id: 'netsec.vnr.nav.security.insights',
      config: {
        stateLoaders: ['auth', 'reporting'],
        navigation: () => [{
          key: 'insights',
          path: '/insights',
          title: 'Insights',
          expandable: true,
          children: [{
            key: 'unified_network_activity_report',
            title: 'Activity Insights',
            path: '/insights/activity_insights',
            avail: () => {
              if (hideReporting()) {
                return false;
              }
              const reportById = getReportsById();
              return reportById ? reportById.unified_network_activity_report.allowed : false;
            }
          }, {
            key: 'operational-insights',
            path: '/insights/operational',
            title: 'Operational',
            type: 'group',
            children: [{
              key: 'prisma-sase',
              title: 'Prisma SASE',
              path: '/insights/operational/prisma-sase',
              children: [{
                key: 'prisma-usage',
                title: 'Prisma Access Usage',
                path: '/insights/operational/prisma-sase/usage',
                avail: () => {
                  if (hideReporting()) {
                    return false;
                  }
                  const reportById = getReportsById();
                  return reportById ? reportById.prisma_access_report.allowed : false;
                }
              }]
            }
            ]
          }, {
            key: 'security-insights',
            path: '/insights/security',
            title: 'Security',
            type: 'group',
            children: [
              {
                key: 'advanced_threat_prevention_report',
                title: 'Advanced Threat Prevention',
                path: '/insights/security/advanced_threat_prevention',
                avail: () => {
                  const reportById = getReportsById();
                  return reportById ? reportById?.advanced_threat_prevention_report?.allowed : false;
                }
              },
              // report only view for v3
              // {
              //   key: 'executive_report',
              //   title: 'Executive Summary',
              //   path: '/insights/security/executive',
              //   // replacePaths: '/dashboards/executive',
              //   avail: () => {
              //     const reportById = getReportsById();
              //     return reportById ? reportById?.executive_report?.allowed : false;
              //   }
              // },
              {
                key: 'dns_report',
                title: 'DNS Security',
                path: '/insights/security/dns',
                avail: () => {
                  const reportById = getReportsById();
                  return reportById ? reportById?.dns_report?.allowed : false;
                }
              },
              {
                key: 'ioc_report',
                title: 'Threats Intelligence',
                path: '/insights/security/threats-intelligence',
                avail: () => {
                  if (hideReporting()) {
                    return false;
                  }
                  const reportById = getReportsById();
                  return reportById ? reportById.ioc_report.allowed : false;
                }
              },
              // deprecated except for few regions
              {
                key: 'app_usage_report',
                title: 'Application Usage',
                path: '/insights/security/app_usage',
                avail: () => {
                  const reportById = getReportsById();
                  return reportById ? reportById?.app_usage_report?.allowed : false;
                }
              },
              // deprecated except for few regions
              {
                key: 'network_activity',
                title: 'Network Activity',
                path: '/insights/security/network_activity',
                avail: () => {
                  const reportById = getReportsById();
                  return reportById ? reportById?.network_activity_report?.allowed : false;
                }
              },
              // deprecated except for few regions
              {
                key: 'threat_insights',
                title: 'Threat Insights',
                path: '/insights/security/threat_insights',
                replacePaths: '/dashboards/threat_insights/*',
                avail: () => {
                  const reportById = getReportsById();
                  return reportById ? reportById?.threat_insights?.allowed : false;
                }
              },
              // deprecated except for few regions
              {
                key: 'user_activity_report',
                title: 'User Activity',
                path: '/insights/security/uar',
                avail: () => {
                  const reportById = getReportsById();
                  return reportById ? reportById?.user_activity_report?.allowed : false;
                }
              },
              {
                key: 'wildfire_report',
                title: 'WildFire',
                path: '/insights/security/wildfire',
                avail: () => {
                  const reportById = getReportsById();
                  return reportById ? reportById?.wildfire_report?.allowed : false;
                }
              },
              {
                key: 'posture',
                title: 'POSTURE',
                type: 'section',
                path: '/insights/security/posture',
                children: [
                  {
                    key: 'best_practices_report',
                    title: 'Best Practices',
                    path: '/insights/security/posture/bpa',
                    avail: () => {
                      const reportById = getReportsById();
                      return reportById ? reportById?.best_practices_report?.allowed : false;
                    }
                  }
                ]
              }
            ]
          }]
        }],
        isAllowed: () => true
      }
    },
    {
      id: 'netsec.vnr.nav.logviewer',
      config: {
        stateLoaders: ['auth', 'reporting'],
        navigation: () => [{
          key: 'logviewer',
          title: 'Log Viewer',
          path: '/logviewer',
          avail: () => {
            const reportById = getReportsById();
            return reportById ? reportById.log_viewer.allowed : false;
          }
        }],
        isAllowed: () => {
          return true;
        }
      }
    }
  ]
} satisfies ExtensionsConfig<SparkyLeftNavConfig>;
