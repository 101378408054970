import Registry from "@ui-platform/extensibility";
import React from "react";
import dashboards from "./dashboards";
import PDFReportsExtensions from "./pdf-reports";
import navStateLoader from "./navTsgStateLoader";
import leftNavExt from "./leftNavExt";
import routesExt from "./routesExt";
import dynamicLeftNavExt from "./dynamicLeftNavExt";

// Supporting Log Forwarding in SCM UI
// TODO: Migrate to Extensibility 2.0 just like
Registry.contributeExtension("vnr.components", {
  id: "vnr_log_forwarding",
  config: {
    id: "vnr_log_forwarding",
    title: "vnr_log_forwarding",
    widgetSettings: [],
    settingsValues: {},
    team: "vnr",
    groupConfig: {},
    component: React.lazy(async () => await import("../LogForwarding")),
    enabled: true
  }
});

export default [dashboards, PDFReportsExtensions, navStateLoader, leftNavExt, routesExt, dynamicLeftNavExt];
