import {
  getFrameworkServiceUrl,
  getAuthState,
  getMicroAppVars,
  fawkesUtils,
  getFeatureFlag,
  getExposedSync
} from "@sparky/framework";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";
// import md5 from "md5";
import sha1 from "sha1";

// const md5Salt = "";
const sha1Salt = "";

function getPaParams(hasTsgSupport: boolean, main: any, StartupManager: any) {
  const isFawkesRunning = getAuthState()?.instances?.isFawkesRunning ?? false;
  const paEnabled = get(main, "tenant.pa_enabled", isFawkesRunning);
  const paiVars = getMicroAppVars("pai") ?? {};
  const paiServer = paiVars.apiServer?.replace(/(^\w+:|^)\/\//, "");
  const cosmoFQDN = getFrameworkServiceUrl("cosmos")?.replace(/(^\w+:|^)\/\//, "") ?? "";
  const redirectMap = getExposedSync("management-app", "ConfigObjectRedirectMap");
  const enableRuleDetails = getFeatureFlag("vnr.enable_rule_details") ?? false;
  const getRuleDetailsPageHeaders = () => {
    const now = new Date().getTime();
    const isGov = main?.loggedInUser?.tenant?.is_gov ?? false;
    console.log({ now, isGov });
    return {
      // salt should have been sent by Admin node already.
      "x-csrf-token": isGov ? now + ":" + sha1(`${now}:${sha1Salt}`) : fawkesUtils?.getCSRFToken(),
      // by this time, session value must be already set.
      "x-auth-jwt": fawkesUtils?.getSession(),
      "x-trace-id": uuidv4(),
      "content-type": "application/json"
    };
  };
  // remove extra slash from the FQDN
  const ruleDetailsPageParams = {
    isAvailiable: enableRuleDetails,
    fqdn: fawkesUtils.getRedirectURL().replace(/\/$/g, ""), // remove extra slash
    getHeaders: getRuleDetailsPageHeaders,
    redirectMap
  };

  return hasTsgSupport
    ? {
        paEnabled,
        paiFQDN: paiServer ?? cosmoFQDN,
        ruleDetailsPageParams
      }
    : {
        paEnabled,
        paiFQDN: get(StartupManager.getReportingFQDNs(), "paiFQDN", cosmoFQDN),
        ruleDetailsPageParams
      };
}

export default getPaParams;
